import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  data: null,
  title: null,
  index:{
      articles:null,
      queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
      }
  },
    create:{
        titles:[],
        subtitles:[],
        content:null
    },
    archived: {
        articles: null,
        queries: {
            per_page: 10,
            page: 1,
            status: 'archived',
            search: null,
            sort_by: 'id',
            sort_desc: false,
        },
        total: 0,
    },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { articles, page, queries, total }) {
      state[page] = {
        articles: articles,
        queries: queries,
        total: total,
      }
    },
    currentPage(state, page) {
      state.currentPage = page
    },
    createTitlesSuccess(state, data) {
      if (state.create.titles && Array.isArray(data)) {
        state.create.titles = data;
      }
    },
    createContentSuccess(state, data) {
      if (state.create.content) {
          state.create.content = data;
      }
    },
    createSubTitleSuccess(state, data) {
      if (state.create.subtitles && Array.isArray(data)) {
          state.create.subtitles = data;
      }
    },
    createTitleRefreshed(state, data) {
        if (this.state.content.create.titles && Array.isArray(this.state.content.create.titles)) {
            const old_index = this.state.content.create.titles.findIndex((el) => el.title === data.from.title);
            this.state.content.create.titles[old_index].title = data.to;
        }
    },
    createSubTitleRefreshed(state, data) {
      if (this.state.content.create.subtitles && Array.isArray(this.state.content.create.subtitles)) {
          const old_index = this.state.content.create.subtitles.findIndex((el) =>el.title === data.from);
          this.state.content.create.subtitles[old_index].title = data.to;
      }
    },
    getContentSuccess(state,data){
        state.create.subtitles = data.subheadings;
    },
    archiveSuccess(state) {
      state.archived.articles = null
    },
  },

  actions: {
    resetAll({commit}) {
      commit('resetAllSuccess')
    },

    getAll({commit, state}, {queries, page}) {
      return new Promise((resolve, reject) => {
        axios
            .get('content/all-articles', {params: queries})
            .then((res) => {
              commit('getAllSuccess', {
                articles: res.data.data,
                page: page,
                queries: queries,
                total: res.data.total,
              })
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    refreshTitle({commit}, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`content/refreshTitle`, data)
            .then((res) => {
              const refresh = {
                  from:data.title,
                  to:res.data.data
              }
              commit('createTitleRefreshed',refresh)
              resolve(res.data);
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    refreshSubTitle({commit}, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`content/refreshSubTitle`, data)
            .then((res) => {
                const refresh = {
                    from:data.title,
                    to:res.data.data
                }
                commit('createSubTitleRefreshed',refresh)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    generateTitles({commit}, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`content/generateTitle`, data)
            .then((res) => {
              commit('createTitlesSuccess', res.data.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    rewriteContent({commit}, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`content/rewriteContent`, data)
                .then((res) => {
                    commit('createContentSuccess', res.data.data)
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    generateContent({commit}, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`content/generateContent`, data)
            .then((res) => {
                commit('createContentSuccess', res.data.data)
                resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    generateSubtitles({commit}, data) {
      return new Promise((resolve, reject) => {
        axios
            .post(`content/generateSubtitles`, data)
            .then((res) => {
              commit('createSubTitleSuccess', res.data.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },
    saveContent({ commit }, content) {
      return new Promise((resolve, reject) => {
          axios
              .post(`content/save`,content)
              .then((res) => {
                  resolve(res.data.data)
              })
              .catch((err) => {
                  reject(err)
              })
      })
    },
    getData({ commit }, contentId) {
      return new Promise((resolve, reject) => {
        axios
            .get(`content/getData/${contentId}`)
            .then((res) => {
                commit('getContentSuccess',res.data.data);
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },
    archive({ commit }, contentId) {
      return new Promise((resolve, reject) => {
          axios
              .delete(`/content/${contentId}`)
              .then((res) => {
                  commit('archiveSuccess')
                  resolve(res);
              })
              .catch((error) => {
                  reject(error)
              })
      })
    },
  },


  getters: {
      total(state) {
          return state[state.currentPage].total
      },
      titles(state) {
          return state.create.titles;
      },
      content(state) {
          return state.create.content;
      },
      subTitles(state){
          return state.create.subtitles;
      },
      queries(state) {
          return state[state.currentPage].queries
      },
      all(state) {
          return state[state.currentPage].articles
      },
  },
    setters:{
        subTitles(newValue){
            this.state.create.subtitles = newValue;
        }
    }
}
