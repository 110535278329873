<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  created: () => {
    document.body.classList.add('authentication-bg')
    document.body.classList.add('authentication-bg-pattern')
  },
  // metaInfo () {
  //   return {
  //     link: [
  //       { hid: 'favorite-icon', rel: 'icon', href: 'https://reviewour.biz/favicon.ico' , type: 'image/x-icon' }
  //     ]
  //   }
  // },
}
</script>
