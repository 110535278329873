<template>
  <div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
    <div class="container-fluid">
      <router-link
        v-if="config && userRole"
        class="navbar-brand mr-0 mr-md-2 logo"
        :to="'/' + userRole.toLocaleLowerCase()"
      >
        <span class="logo-lg">
          <img src="@assets/images/logo.png" alt="Copy Engine" width="200" />
        </span>
        <span class="logo-sm">
          <img src="@assets/images/favicon.png" alt="Copy Engine" height="40" />
        </span>
        <!-- <span class="logo-lg">
          <img src="@assets/images/logo.png" alt height="50" />
        </span>
        <span class="logo-sm">
          <img src="@assets/images/favicon.png" alt height="50" />
        </span> -->
      </router-link>
      <ul
        class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0 mr-auto"
      >
        <li>
          <button
            class="button-menu-mobile open-left"
            :class="{ open: isMenuOpened }"
            @click="toggleMenu"
          >
            <feather type="menu" class="menu-icon align-middle"></feather>
            <feather type="x" class="close-icon"></feather>
          </button>
        </li>
      </ul>
      <ul
        class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0"
      >
        <li v-if="user && user.role === 'Admin'" class="mt-auto mb-auto mr-4">
          <router-link
              :to="{ name: 'admin.agencies.create' }"
              class="btn btn-light"
          >
            <i class="uil uil-plus"></i> New Agency
          </router-link>
        </li>
        <b-nav-item-dropdown
            id="user-info"
            right
            variant="white"
            class="user-list"
            menu-class="dropdown-lg"
        >
          <template v-if="user" v-slot:button-content>
            <img :src="user.avatar" class="avatar-sm rounded-circle mr-2" />
            <span class="text-primary font-weight-bold">{{ user.full_name }}</span>
            <feather type="chevron-down" class="align-middle ml-2"></feather>
          </template>
          <b-dropdown-item v-if="isImpersonating" @click="stopImpersonate">
            <feather type="log-in" class="icon-dual icon-xs mr-2"></feather>
            <span>Main account</span>
          </b-dropdown-item>
          <b-dropdown-item
              v-if="user"
              :to="{ name: `${userRole.toLocaleLowerCase()}.profile` }"
          >
            <feather type="user" class="icon-dual icon-xs mr-2"></feather>
            <span>My Account</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item v-if="user" :to="{ name: `${userRole.toLocaleLowerCase()}.notifications.index` }">
            <feather type="headphones" class="icon-dual icon-xs mr-2"></feather>
            <span>Notification</span>
          </b-dropdown-item> -->

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item class="notify-item p-0" @click.prevent="logout">
            <feather type="log-out" class="icon-dual icon-xs mr-2"></feather>
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },

  data() {
    return {
      loadingStopImpersonate: false,
      isMenuOpened: false,
      loadingMark: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    config() {
      return this.$store.getters['app/config']
    },

    notifications() {
      return this.$store.getters['user/notifications'] || []
    },

    totalUnreadNotifications() {
      return this.notifications.length
    },
  },

  mounted() {
    // document.body.classList.toggle('left-side-menu-condensed')
  },

  methods: {
    toggleMenu() {
      document.body.classList.toggle('left-side-menu-condensed')
      document.body.classList.toggle('sidebar-enable')
      this.$emit('toggleMenu', true)
    },

    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + this.userRole.toLocaleLowerCase())
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },

    markAllAsRead() {
      this.loadingMark = true
      this.$store
        .dispatch('user/markAllNotificationAsRead')
        .then(() => {
          this.loadingMark = false
        })
        .catch(() => {
          this.loadingMark = false
        })
    },
  },
}
</script>

<style lang="scss">
.noti-scroll {
  height: 220px;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}
.logo{
  background: #284373;
  padding: 0;
  min-width: 75px;
  .logo-lg{
    height: 55px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-sm{
    height: 55px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.logo-text {
  color: white;
  font-weight: bold;
  font-size: 24px;
}
</style>
